export default class Footer {
  constructor() {
    this.el = document.querySelector('#footer');
    this.chars = ['♡', '☺', '✄', '✰', '☃', '⌘', '◐', '∞', '☮', '◎'];
    setTimeout(() => this.switchChar(), Math.round(Math.random()*4) + 2);
  }
  switchChar() {
    this.el.innerText = this.chars[Math.floor(Math.random()*this.chars.length)];

    setTimeout(() => this.switchChar(), Math.round(Math.random() * 800) + 200);
  }
}
