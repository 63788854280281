import Circles from './js/Circles';
import Logo from './js/Logo';
import Footer from './js/Footer';

const footer = new Footer();

const circleEls = document.querySelectorAll('.divider');
const logo = new Logo();

for (let i = 0; i < circleEls.length; i++) {
  let circ = new Circles(circleEls[i]);
}

const colors = [
  ['#000', '#fff'],
  ['#fff', '#000'],
  ['#f00', '#ff0'],
  ['#00f', '#ff0'],
  ['#0ff', '#EF68F0'],
  ['#EF68F0', '#00f'],
];
const updateColors = () => {
  let col = colors[Math.floor(Math.random()*colors.length)];
  document.documentElement.style.setProperty('--text-color', col[1]);
  document.documentElement.style.setProperty('--bg-color', col[0]);
};
window.setTimeout(updateColors, 1000);
window.setInterval(updateColors, 5000);
