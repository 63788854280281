import { SVG } from '@svgdotjs/svg.js';

export default class Logo {
  constructor(el) {
    this.eventTimeout = null;
    this.el = document.querySelector('#logo');
    this.draw = SVG().addTo(this.el);
    this.group = this.draw.group();
    this.updateCanvas();
    this.drawLogo();
    window.addEventListener('resize', () => this.handleResize(), false);
    window.addEventListener('scroll', () => this.handleScroll());
    window.setInterval(() => this.drawLogo(), 5000);
  }
  updateCanvas() {
    this.size = {
      width: this.el.clientWidth,
      height: this.el.clientHeight,
    };
    this.draw.size(this.size.width, this.size.height);
  }
  drawLogo() {
    this.updateCanvas();
    this.group.clear();
    this.chars = [
      this.group.path(
        'M36.55,109.65 C47.5,109.65 55.75,104.7 60.25,97.05 L60.25,108 L79.3,108 L79.3,5.68434189e-14 L59.95,5.68434189e-14 L59.95,39.75 C55.45,32.85 47.5,28.5 36.7,28.5 C17.65,28.5 0.4,42.75 0.4,68.55 C0.4,95.4 17.05,109.65 36.55,109.65 Z M40.3,93.75 C28.6,93.75 20.2,84.45 20.2,68.55 C20.2,53.4 28.6,44.4 40.3,44.4 C51.85,44.4 60.4,53.25 60.4,68.55 C60.4,84.45 51.4,93.75 40.3,93.75 Z'
      ),
      this.group.path(
        'M19.4,18 L19.4,0 L-0.1,0 L-0.1,18 L19.4,18 Z M19.25,108 L19.25,30.3 L0.05,30.3 L0.05,108 L19.25,108 Z'
      ),
      this.group.path('M19.25,108L19.25 0 0.05 0 0.05 108z'),
      this.group.path(
        'M28.2,109.65 C37.95,109.65 45.9,106.05 50.85,98.85 L50.85,108 L69.6,108 L69.6,30.3 L50.4,30.3 L50.4,73.2 C50.4,86.85 42.75,93.15 34.5,93.15 C25.35,93.15 19.35,87 19.35,75.9 L19.35,30.3 L-4.26325641e-14,30.3 L-4.26325641e-14,79.05 C-4.26325641e-14,99.3 11.7,109.65 28.2,109.65 Z'
      ),
      this.group.path(
        'M19.25,109 L19.25,66.85 C19.25,52.75 26.9,46 35.75,46 C45.5,46 51.2,52.75 51.2,63.4 L51.2,109 L70.4,109 L70.4,60.55 C70.4,40.15 59.3,29.5 42.2,29.5 C32.3,29.5 23.9,33.55 18.95,40.6 L18.95,31.3 L0.05,31.3 L0.05,109 L19.25,109 Z'
      ),
      this.group.path(
        'M41.05,108.65 C63.55,108.65 81.85,92.6 81.85,67.7 C81.85,42.95 63.7,27.5 41.05,27.5 C18.55,27.5 0.1,43.25 0.1,67.7 C0.1,92.9 18.25,108.65 41.05,108.65 Z M41.05,92.45 C28.45,92.45 19.9,82.4 19.9,67.7 C19.9,53.45 28.3,43.7 41.05,43.7 C53.5,43.7 62.05,53.6 62.05,67.7 C62.05,82.55 53.5,92.45 41.05,92.45 Z'
      ),
    ];

    this.wordWidth = this.calculateWordWidth();

    this.oldHeight = this.group.height();
    this.scaleFactor = this.size.height / this.oldHeight;
    this.sizeRatio = this.size.width / this.size.height;
    this.group.size(null, this.size.height);

    this.distributeCharsRandom();
    this.handleScroll();
  }
  randomG(v) {
    var r = 0;
    for (var i = v; i > 0; i--) {
      r += Math.random();
    }
    return r / v;
  }
  distributeCharsRandom() {
    let spacing = Math.round(Math.random() * 10) + 5;
    let availableSpace = this.oldHeight * this.sizeRatio - this.wordWidth;
    let charCount = Math.floor(availableSpace / spacing);
    spacing = availableSpace / charCount;

    let countPerChar = [0, 0, 0, 0, 0, 0];
    while (charCount > 0) {
      countPerChar[
        Math.floor(
          this.randomG(Math.ceil(Math.random() * 10)) * this.chars.length
        )
      ] += 1;
      charCount--;
    }

    let pos = 0;
    this.chars.forEach((char, i) => {
      char.x(pos);
      while (countPerChar[i] > 0) {
        pos += spacing;
        let cop = char.clone();
        this.group.add(cop);
        cop.x(pos);
        countPerChar[i]--;
      }
      pos += char.width() + 15;
    });
  }
  calculateWordWidth() {
    let w = 0;
    this.chars.forEach((char) => {
      w += char.width();
    });
    w += 5 * 15;
    return w;
  }
  handleResize() {
    if (!this.eventTimeout) {
      this.eventTimeout = setTimeout(() => {
        this.eventTimeout = null;
        this.drawLogo();
      }, 500);
    }
  }
  handleScroll() {
    let wH = window.innerHeight;
    let opa = (wH - window.scrollY) / wH;
    if (opa < 0) opa = 0;
    this.el.style.opacity = opa;
  }
}
