import { SVG } from '@svgdotjs/svg.js';

export default class Circles {
  constructor(el) {
    this.eventTimeout = null;
    this.drawTimers = [];
    this.el = el;
    this.size = [this.el.clientWidth, this.el.clientHeight];
    this.draw = SVG().addTo(this.el).size(this.size[0], this.size[1]);
    this.drawCircles();

    window.addEventListener('resize', () => this.handleResize(), false);
    this.el.addEventListener('mouseenter', () => this.drawCircles());
  }
  drawCircles() {
    while (this.drawTimers.length > 0) {
      clearTimeout(this.drawTimers.pop());
    }

    this.el.classList.remove('hidden');
    this.size = [this.el.clientWidth, this.el.clientHeight];
    this.draw.size(this.size[0], this.size[1]);
    this.draw.clear();
    let inverted = Math.round(Math.random());
    let wiggle = this.size[0] / 15;
    let corr = Math.floor(Math.random() * wiggle - wiggle / 2);
    let circleCount = Math.round(this.size[0] / 13 + corr);
    let margin = (this.size[0] - this.size[1]) / (circleCount - 1);
    for (let i = 0; i < circleCount; i++) {
      this.drawTimers.push(
        setTimeout(() => {
          let c = this.draw.circle(this.size[1]);
          c.attr({
            fill: 'transparent',
            'stroke-width': 0.8,
          });
          if(inverted) {
            c.move((this.size[0] - this.size[1]) - i * margin, 0);
          }else{
            c.move(i * margin, 0);
          }
        }, (500/circleCount) * i)
      );
    }
  }
  handleResize() {
    this.el.classList.add('hidden');
    if (!this.eventTimeout) {
      this.eventTimeout = setTimeout(() => {
        this.eventTimeout = null;
        this.drawCircles();
      }, 500);
    }
  }
}
